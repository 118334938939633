import React, { Component } from "react"
import { FiX, FiMenu } from "react-icons/fi"
import Scrollspy from "react-scrollspy"
import logo from "../../../images/logoProtafoliov4.svg"
import { FormattedMessage } from "gatsby-plugin-react-intl"
//const LanguajeSelect = loadable(() => import("../../Languaje/Languajev2"))
import LanguajeSelect from "../../Languaje/Languajev2"
//import LanguajeSelect from "../../Languaje/Languajev2"

//import ThemeToggler from "../../ThemeToggler"

/* const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/iothmanosx" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/in/othmanosx" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/othmanosx" },
  { Social: <FaTwitter />, link: "https://twitter.com/othmanosx" },
  { Social: <FaGithub />, link: "https://github.com/othmanosx" },
] */
class HeaderThree extends Component {
  constructor(props) {
    super(props)
    this.menuTrigger = this.menuTrigger.bind(this)
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this)
    this.stickyHeader = this.stickyHeader.bind(this)

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
  }
  componentDidMount() {
    // Runs after the first render() lifecycle
    window.addEventListener("load", function () {
      console.log("All assets are loaded")
    })
    window.addEventListener("scroll", function () {
      var value = window.scrollY
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky")
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky")
      }
    })
    var elements = document.querySelectorAll(".has-droupdown > a")
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active")
          this.classList.toggle("open")
        }
      }
    }
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open")
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open")
  }

  stickyHeader() {}

  render() {
    const { color = "default-color" } = this.props
    let logoUrl = (
      <img
        style={{ width: "80px", opacity: 0.8 }}
        src={logo}
        alt="Ariel Bravo"
        width="60"
        height="60"
      />
    )

    return (
      <header className={`header-area header-style-two header--fixed ${color}`}>
        <div className="header-wrapper">
          <div className="header-left d-flex align-items-center">
            <div className="logo">
              <a href={this.props.homeLink}>{logoUrl}</a>
            </div>
            <nav className="mainmenunav d-lg-block ml--50">
              <Scrollspy
                className="mainmenu"
                items={[
                  "home",
                  "about",
                  "service",
                  "portfolio",
                  "blog",
                  "contact",
                ]}
                currentClassName="is-current"
                offset={-200}
              >
                <li>
                  <a href="#home">
                    <FormattedMessage id="home" />
                  </a>
                </li>
                <li>
                  <a href="#about">
                    <FormattedMessage id="about" />
                  </a>
                </li>
                <li>
                  <a href="#service">
                    <FormattedMessage id="service" />
                  </a>
                </li>
                <li>
                  <a href="#portfolio">
                    <FormattedMessage id="portfolio" />
                  </a>
                </li>
                {/* <li>
                  <a href="#blog">Blog</a>
                </li> */}
                <li>
                  <a href="#contact">
                    <FormattedMessage id="contact" />
                  </a>
                </li>
              </Scrollspy>
            </nav>
          </div>
          <div className="header-right">
            <div className="social-share-inner">
              <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                <li>
                  {" "}
                  <LanguajeSelect />
                </li>

                {/* {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a href={`${val.link}`}>{val.Social}</a>
                  </li>
                ))} */}
              </ul>
            </div>
            {/* <div className="header-btn">
              <a className="rn-btn" href="#contact">
                <span>Contact me</span>
              </a>
            </div> */}
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                onKeyDown={this.menuTrigger}
                aria-hidden="true"
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span
                aria-hidden="true"
                onClick={this.CLoseMenuTrigger}
                onKeyDown={this.CLoseMenuTrigger}
                className="closeTrigger"
              >
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    )
  }
}
export default HeaderThree
