import React, { useState } from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-react-intl"
import { FaCaretDown, FaGlobe } from "react-icons/fa"
import * as styles from "./Languajev2.module.css"
/* import "bootstrap/dist/css/bootstrap.min.css" */

const handleChangeLanguaje = (e, currentLoc) => {
  e.preventDefault()
  if (currentLoc === "en") {
    changeLocale("es")
  } else {
    changeLocale("en")
  }
}

const Languajev2 = () => {
  const [open, setOpen] = useState(false)
  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <>
          <nav className={styles.navLang}>
            <div
              className={styles.drop_btn}
              aria-hidden="true"
              onClick={() => {
                setOpen(!open)
              }}
              onKeyDown={() => {
                setOpen(!open)
              }}
            >
              {" "}
              <FaGlobe
                style={{
                  display: "inline-block",
                  fontSize: "35px",
                  lineHeight: "80px",
                  paddingRight: "15px",
                }}
              />
              {currentLocale === "en"
                ? "English"
                : currentLocale === "es"
                ? "Español"
                : "Indefinido"}
              <span className={`fas fa_caret_down`}>
                <FaCaretDown
                  style={{ paddingLeft: "15px", paddingTop: "5px" }}
                />
              </span>
            </div>

            <div
              className={styles.wrapper}
              style={{ display: open ? "flex" : "none" }}
            >
              <ul
                className="menu_bar"
                aria-hidden="true"
                onClick={e => handleChangeLanguaje(e, currentLocale)}
                onKeyDown={e => handleChangeLanguaje(e, currentLocale)}
              >
                <li>
                  <a href="/">
                    {currentLocale === "en"
                      ? "Español"
                      : currentLocale === "es"
                      ? "English"
                      : "Indefinido"}
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </>
      )}
    </IntlContextConsumer>
  )
}

export default Languajev2
