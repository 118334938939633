import React from "react"
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaGithub,
} from "react-icons/fa"

const SocialShare = [
  {
    name: "Facebook",
    Social: <FaFacebookF />,
    link: "https://www.facebook.com/ariel.bravo.3994/",
  },
  {
    name: "Linkedin",
    Social: <FaLinkedinIn />,
    link: "https://linkedin.com/in/ariel-bravo-chuqui-6b49321b8/",
  },
  {
    name: "Instagram",
    Social: <FaInstagram />,
    link: "https://www.instagram.com/ariel.bravo.3994/",
  },
  {
    name: "Twitter",
    Social: <FaTwitter />,
    link: "https://twitter.com/ArielBravo19",
  },
  {
    name: "Github",
    Social: <FaGithub />,
    link: "https://github.com/arielBravoEc",
  },
]

export default SocialShare
